// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  COGNITO_URL: "https://myidtravel-test.auth.eu-central-1.amazoncognito.com",
  CLIENT_ID: "70ds2qsrsum3v1oskisj3f7ksf",
  CLIENT_SECRET: "2t1njq78p3v11n4mpup6cfcflmp4c56b2205egu9rh73ie27b43",
  REDIRECT_URI: "https://myidtravel-test.ibm-apps.internal.cloud.finnair.com",
  //REDIRECT_URI: "https://myidtravel-test.ibm-apps.internal.cloud.finnair.com/duty/login.html",
  //REDIRECT_URI: "http://localhost:4200",
  LOGOUT_URI: "https://myidtravel-test.ibm-apps.internal.cloud.finnair.com",
  LEISURE_URI: "http://fir2s08.finnair.fi/MyIDTravelWeb/leisure",
  DUTY_URI: "http://fir2s08.finnair.fi/MyIDTravelWeb/duty"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
