import {Component } from '@angular/core';
import { environment } from "src/environments/environment";
import { AuthService } from './_services/index';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'myidtravel-ui';
  
  constructor(private spinner: NgxSpinnerService, private authService: AuthService){
      if(window.location.href.indexOf('id_token')>0){
          let token = this.getUrlParameter("id_token");        
          //console.log(token[0][1]);
          localStorage.setItem("id_token",token[0][1]);
          let access = this.getUrlParameter("access_token");        
          //console.log(access[0][1]);
          localStorage.setItem("access_token",access[0][1]);
          //window.location.href = environment.LEISURE_URI+"?iv-user="+token[0][1];
          this.authService.getUserInfo().subscribe(
                  userData => {
                      console.log( "In Get User Info Success" );
                      console.log(userData);
                      //localStorage.setItem("name", userData.name);
                      localStorage.setItem("userId", userData.nickname);
                      //localStorage.setItem("role", userData.profile);                      
                      this.spinner.hide();
                      window.location.href = environment.LEISURE_URI+"?iv-user="+window.btoa(userData.nickname);
                  },
                  userErr => {
                      console.log( "In Get User Info Fail" );
                      console.log(userErr);
                  }
          );
      }else{
          window.location.href = environment.COGNITO_URL+"/login?response_type=token&client_id="+environment.CLIENT_ID+"&redirect_uri="+environment.REDIRECT_URI;
      }      
  }
  
  private getUserId(ayId: string){
      let userid = "";
      if(ayId.toUpperCase().includes("AY") || ayId.toUpperCase().includes("UP")){
          userid = ayId.substring(2);
      }else{
          userid = ayId;
      }
      
      if(parseInt(userid) != 33880){
          if(userid.startsWith("0")){
              userid = userid.substring(1);
          }else{
              if(parseInt(userid) >= 10000 && parseInt(userid) <= 39999){
                  userid = userid + parseInt(userid)%7;
              }       
          }
      }
            
      console.log("final userid",userid);
      return userid;
  }
  
  private getUrlParameter(sParam: any) {
      //console.log(decodeURIComponent(window.location.href.substring(window.location.href.indexOf('id_token'))).split('&'));
      return decodeURIComponent(window.location.href.substring(window.location.href.indexOf(sParam))).split('&')
       .map((v) => { return v.split("=") })
       .filter((v) => { return (v[0] === sParam) ? true : false }); 
    };
}


