import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../_services/index';
import { switchMap } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(private spinner: NgxSpinnerService, private authService: AuthService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();         

        return next.handle(this.processRequest(request));
        /*.catch(error => {
           
            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            console.log(error);
            if (error.status !== 401) {
                if(this.refreshTokenInProgress){
                    this.refreshTokenInProgress = false;
                    this.authService.logOut();
                }
                return throwError(error);
            }

            if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject
                    .filter(result => result !== null)
                    .take(1)
                    .switchMap(() => next.handle(this.processRequest(request)));
            } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                // Call auth.refreshAccessToken(this is an Observable that will be returned)
                return this.authService
                    .getRefreshToken()
                    .switchMap((token: any) => {
                        //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                        // for the next time the token needs to be refreshed
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(token);

                        return next.handle(this.processRequest(request));
                    })
                    .catch((err: any) => {
                        this.refreshTokenInProgress = false;

                        this.authService.logOut();
                        return throwError(err);
                    });
            }
        });*/
    }
    
    private processRequest(request: HttpRequest<any>){
     // add authorization header with jwt token if available
        let token = localStorage.getItem("id_token");
        console.log("in JwtInterceptor");
        //console.log(JSON.stringify(request));
        let authorization = `${token}`;
        let contentType = 'application/json';
        /*let isOption = false;
        if(request.url.includes("mealoption")){
            isOption = true;
        }*/
        if(request.url.includes("token")){
            authorization = 'Basic ' + window.btoa(environment.CLIENT_ID + ":" +environment.CLIENT_SECRET);
            contentType = 'application/x-www-form-urlencoded';
        }
        if(request.url.includes("userInfo")){
            authorization = 'Bearer ' + localStorage.getItem("access_token");
        }
         //if (token) {
            return request.clone({
                setHeaders: { 
                    Authorization: authorization,
                    //Authorization: 'Bearer mock-allow',
                   // 'x-api-key': environment.X_API_KEY,
                    'Content-Type': contentType
                }
            });
        //}
    }
}