import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable()
export class AuthService {
    
    constructor(private http: HttpClient) { 
    }
    
    getRefreshToken(){
        let bodyArr:any = ["grant_type=refresh_token","client_id="+environment.CLIENT_ID,"refresh_token="+localStorage.getItem("refresh_token")];
        return this.http.post<any>(
        environment.COGNITO_URL+"/oauth2/token", bodyArr.join("&")
    ).pipe(map(resp =>{
        console.log(resp);
        localStorage.setItem("id_token",resp.id_token);
        localStorage.setItem("access_token",resp.access_token);
        return resp.id_token;
    }));
    }
    
    getUserInfo(){
        return this.http
        .get<any>(
            environment.COGNITO_URL+"/oauth2/userInfo"
        );
    }
    
    logOut(){
        window.location.href = environment.COGNITO_URL+"/logout?response_type=code&client_id="+environment.CLIENT_ID+"&redirect_uri="+environment.REDIRECT_URI+"&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin";
    }   
}
